<template>

  <!--  Content Parameters-->
  <div v-if="isStyle">

      <h4 :style="`color : ${wsDARKER}`"> {{ $t('format.text_padding') }}: </h4>
      <ws-button-group
          @input="changePadding"
          v-model="paddingSize"
          :items="paddingsSelect"
          class="mb-2 mt-2"
      />

    <v-expand-transition>
      <div v-if="paddingSize === 'custom' ">

        <a-parameter-slider
            @change="changePaddingSlider($event , 'horizontal')"
            v-model="horziontalPadding"
            :default="0"
            :value-color="wsACCENT"
            :track-color="wsBACKGROUND"
            label="format.padding.horizontal"
            class="mt-4"
            min="0"
            max="350"
        />
        <a-parameter-slider
            @change="changePaddingSlider($event , 'vertical')"
            v-model="verticalPadding"
            :default="0"
            :value-color="wsACCENT"
            :track-color="wsBACKGROUND"
            label="format.padding.vertical"
            class="mt-4"
            min="0"
            max="350"
        />

      </div>
    </v-expand-transition>

    <h4 class="mt-5" :style="`color : ${wsDARKER}`"> {{ $t('Style') }}: </h4>
    <ws-button-group
        @input="changeStyle"
        v-model="textStyle"
        :items="styleSelect"
        class="mb-2 mt-2"
    />

    <v-expand-transition>
      <div v-if="textStyle !== 'none' ">
        <color-param-picker
            @input="$emit('input', COPY(element.config))"
            v-model="element.config.background_color"
            label="Color"
            class="pt-2"
            divider
        />


        <a-parameter-slider
            @change="$emit('input' , COPY(element.config) )"
            v-model="element.config.rounded"
            :default="1"
            label="Rounded"
            :value-color="wsACCENT"
            :track-color="wsBACKGROUND"
            min="1"
            max="200"
        />

      </div>
    </v-expand-transition>

    <v-expand-transition>
      <div    v-if="element.config.is_outlined">
        <a-parameter-slider
            @change="$emit('input' , COPY(element.config) )"
            v-model="element.config.outline_width"
            :default="1"
            label="Thickness"
            :value-color="wsACCENT"
            :track-color="wsBACKGROUND"
            class="pt-2"
            min="1"
            max="10"
        />
        <a-parameter-select
            @change="$emit('input' , COPY(element.config) )"
            v-model="element.config.border_style"
            class="my-5 mt-6"
            :items="borderStylesSelect"
            label="BorderStyle"
            :label-color="wsDARKER"
            :value-color="wsACCENT"
            null-text="Default"
        />

      </div>

    </v-expand-transition>




  </div>
  <div v-else-if="isFormat">

    <h5 :style="`color : ${wsDARKER}`" class="font-weight-regular mb-5">
      {{ $t('format.text_resize.description') }}
    </h5>

    <v-expand-transition>
      <div   v-if="element.config.resize !== 'none' " >
        <h4 :style="`color : ${wsDARKER}`"> {{ $t('format.text_resize.title') }}: </h4>
        <ws-button-group
            v-model="element.config.resize"
            @input="$emit('input', COPY(element.config))"
            :items="moveTextTypesSelect"
            class="mb-2 mt-2"
        />
      </div>

    </v-expand-transition>

    <a-parameter-switch
        @input="disableAutoResizeButton($event)"
        :value="element.config.resize === 'none'"
        class="mt-5"
        label="format.text_resize.none"
    >


    </a-parameter-switch>

  </div>

</template>

<script>
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";
export default {
  name: "avalonTextSettings",
  components : {
    colorParamPicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    isFormat : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      config : {},
      paddingSize : 'custom',
      textStyle : 'none',
      paddingsSelect : [
        { text : 'M' , value : 'm' },
        { text : 'L' , value : 'l' },
        { text : 'XL' , value : 'xl' },
        { icon : 'mdi-dots-vertical' , value : 'custom' }
      ],
      sizes : {
        m : [0 , 0],
        l : [15,15],
        xl : [50,75],
      },
      horziontalPadding : 0,
      verticalPadding : 0
    }
  },
  computed : {
    moveTextTypesSelect() {
      return [
        { text : this.$t('format.text_resize.below') , value : 'below' },
        { text : this.$t('format.text_resize.all')   , value : 'all' },
      ]
    },
    borderStylesSelect() {
      return [
        { text : this.$t('Default') , value : null},
        { text : this.$t('Dashed') , value : 'dashed'},
        { text : this.$t('Dotted') , value : 'dotted'}
      ]
    },
    styleSelect() {
      return [
        {text: this.$t('None'), value: 'none'},
        {text: this.$t('Background'), value: 'background'},
        {text: this.$t('Outlined'), value: 'border'}
      ]
    },
  },
  watch : {
    paddingSize(value) {
      if ( value === 'custom' ) {
        this.checkPaddingSliders()
      }
    }
  },
  methods : {
    disableAutoResizeButton(value) {
      this.element.config.resize = value ?  'none' : 'below';
      this.$emit('input', this.COPY(this.element.config))
    },
    changeStyle(style) {

      if ( style === 'background' ) {
        this.element.config.is_outlined = false
        this.element.config.has_background = true
      }
      else if ( style === 'border' ) {
        this.element.config.is_outlined = true
        this.element.config.has_background = false
      }
      else {
        this.element.config.is_outlined = false
        this.element.config.has_background = false
      }
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))

    },
    checkPaddingSliders() {
      if ( this.element.config.padding_right === this.element.config.padding_left ) {
        this.horziontalPadding  = this.element.config.padding_right
      }

      if ( this.element.config.padding_top === this.element.config.padding_bottom ) {
        this.verticalPadding  = this.element.config.padding_top
      }
    },
    changePadding(size) {
      if ( size === 'custom' ) {
        return
      }

      this.element.config.padding_right = this.sizes[size][0]
      this.element.config.padding_left= this.sizes[size][0]
      this.element.config.padding_top = this.sizes[size][1]
      this.element.config.padding_bottom = this.sizes[size][1]

      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
    changePaddingSlider(value , type) {
      if ( type === 'horizontal' ) {
        this.element.config.padding_right = value
        this.element.config.padding_left  = value
      } else {
        this.element.config.padding_top = value
        this.element.config.padding_bottom = value
      }

      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))

    },
    checkAutoPadding() {
      if ( !this.element.config.padding_right &&
          !this.element.config.padding_left   &&
          !this.element.config.padding_top    &&
          !this.element.config.padding_bottom
      ) {
        this.paddingSize = 'm'
        return
      }
      this.paddingSize = 'custom'
      Object.keys(this.sizes).forEach(size => {
        if ( this.element.config.padding_right  === this.sizes[size][0] &&
            this.element.config.padding_left    === this.sizes[size][0] &&
            this.element.config.padding_top     === this.sizes[size][1] &&
            this.element.config.padding_bottom  === this.sizes[size][1]
        ) {
          this.paddingSize = size
        }
      })


    },

  },
  mounted() {
    this.config = this.COPY(this.value)
    this.checkAutoPadding()
    this.checkPaddingSliders()

    if ( this.config.is_outlined) {
      this.textStyle = 'border'
    }
    else if ( this.config.has_background ) {
      this.textStyle = 'background'
    }
    else {
      this.textStyle = 'none'
    }

    if ( !this.element.config.resize ) {
      this.element.config.resize = 'below'
    }

  }

}
</script>

<style scoped>

</style>